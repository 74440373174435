import { useEffect, useState } from "react";

export function useTimer() {
  const [startMillis] = useState(Date.now())
  const [millis, setMillis] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setMillis(Date.now() - startMillis)
    }, 1000)
  })

  return {
    seconds: Math.floor(millis / 1000) % 60,
    minutes: Math.floor(millis / (60 * 1000)),
  }
}

