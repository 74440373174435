import React, { useEffect, useState, createContext, useContext } from "react";
import Moveable from "react-moveable";
import { useId } from "react-id-generator";
import { useTimer } from "../hooks/timer";
import { faCameraAlt, faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import _ from 'lodash'

function debug() {
  console.log(...arguments)
}

const Actions = createContext({})
const Frames = createContext({})

export function Puzzle({ width, height, children }) {
  const [snapshots, setSnapshots] = useState([])
  const [frames, setFrames] = useState({});
  const [targets, setTargets] = useState([]);

  function addSnapshot(time) {
    setSnapshots(snapshots => {
      debug("SetSnapshots", snapshots, time)
      return snapshots.find(s => _.isEqual(time, s.time)) ? snapshots : [...snapshots, { time, frames }]
    })
  }

  function selectSnapshot(time) {
    setFrames(snapshots.find(s => s.time === time).frames)
    setTargets([])
  }

  return <div className='relative w-full max-w-full min-h-screen flex justify-center items-center bg-yellow'>
    <PuzzleBoard width={width} height={height} snapshots={snapshots} addSnapshot={addSnapshot} selectSnapshot={selectSnapshot}>
      <h1 className="text-2xl">Build the largest square...</h1>
      <p className="pt-4">...without overlapping any shapes.</p>
      <p className="pt-4">In order to move or rotate a shape, click it first. Hold the shift key to expand the current selection.</p>
      <p className="pt-4">This dashed square is the largest square you can build with all available pieces. Let it guide you.</p>
      <p className="pt-4 italic">If you refresh the page, you will have to start all over again. So be careful...</p>
    </PuzzleBoard>
    <PuzzlePieces frames={frames} setFrames={setFrames} targets={targets} setTargets={setTargets}>
      {children}
    </PuzzlePieces>
  </div>
}

function PuzzlePieces({frames, setFrames, targets, setTargets, children}) {
  const [elementGuidelines, setElementGuidelines] = React.useState([]);

  useEffect(() => {
    setElementGuidelines(Array.from(document.querySelectorAll(".snap-target").values()))
  }, []);

  function setTarget(t) {
    setTargets([t])
  }

  function addTarget(t) {
    setTargets(targets => {
      if (targets.find(target => target.id === t.id))
        return targets
      return [...targets, t]
    })
  }

  function initFrame(id) {
    setFrames(frames => ({...frames, [id]: { translate: [Math.floor(Math.random() * 500), Math.floor(Math.random() * 500)], rotate: _snapRotate(Math.random() * 360) }}))
  }

  function updateFrame(id, frame) {
    setFrames(frames => ({...frames, [id]: {...frames[id], ...frame}}))
  }

  return <>
    <svg className="absolute w-full h-screen pointer-events-none">
      <Actions.Provider value={{initFrame, setTarget, addTarget}}>
        <Frames.Provider value={frames}>
          {children}
        </Frames.Provider>
      </Actions.Provider>
    </svg>
    <MoveableControls targets={targets} elementGuidelines={elementGuidelines} frames={frames} updateFrame={updateFrame} />
  </>
}

export function Shape({ color = '#888888', points }) {
  const [id] = useId()
  const {initFrame} = useContext(Actions)

  useEffect(() => {
    initFrame(id)
  }, [id])

  return <MoveableShape id={id} color={color} points={points} />
}

export function Square({ color, size }) {
  return <Shape color={color} points={[{x: 0, y: 0}, {x: size, y: 0}, {x: size, y: size}, {x: 0, y: size}]} />
}

export function Triangle({ color, size }) {
  return <Shape color={color} points={[{x: 0, y: 0}, {x: size, y: 0}, {x: 0, y: size}]} />
}

export function Parallelogram({ color, size }) {
  return <Shape color={color} points={[{x: 0, y: 0}, {x: size, y: 0}, {x: 2 * size, y: size}, {x: size, y: size}]} />
}

function MoveableShape({ id, color, points }) {
  const {setTarget, addTarget} = useContext(Actions)

  return <Frames.Consumer>
    {
      frames => <path id={id}
              d={_path(points)}
              className="pointer-events-auto shadow snap-target"
              style={{
                transformOrigin: _origin(points),
                fill: color,
                transform: frames[id] && _transform(frames[id])
              }}
              onClick={onClick}
        />
    }
  </Frames.Consumer>

  function onClick(e) {
    if (e.shiftKey)
      addTarget(e.target)
    else setTarget(e.target)
  }

  function _path(points) {
    return "M " + points.map((p) => `${p.x} ${p.y}`).join(" L ") + " Z";
  }

  function _origin(points) {
    const c = points.reduce((bounds, p) => ({
      minX: bounds ? Math.min(p.x, bounds.minX) : p.x,
      maxX: bounds ? Math.max(p.x, bounds.maxX) : p.x,
      minY: bounds ? Math.min(p.y, bounds.minY) : p.y,
      maxY: bounds ? Math.max(p.y, bounds.maxY) : p.y
    }), null);

    return `${c.minX + (c.maxX - c.minX) / 2}px ${c.minY + (c.maxY - c.minY) / 2}px`;
  }

  function _transform({translate, rotate}) {
    return `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`;
  }
}

function MoveableControls({ targets, elementGuidelines, frames, updateFrame }) {
  return <Moveable target={targets}
                   originDraggable={false}

                   draggable={true}
                   onDragStart={onDragStart} onDrag={onDrag}
                   onDragGroupStart={onGroup(onDragStart)} onDragGroup={onGroup(onDrag)}

                   rotatable={true}
                   throttleRotate={15}
                   onRotateStart={onRotateStart} onRotate={onRotate}
                   onRotateGroupStart={onGroup(onRotateStart)} onRotateGroup={onGroup(onRotate)}

//                   onClickGroup={onClickGroup}

                   elementGuidelines={elementGuidelines}
                   snappable={true}
                   verticalGuidelines={[0, 200, 400]}
                   horizontalGuidelines={[0, 200, 400]}
                   isDisplaySnapDigit={false}
                   snapCenter={true}
  />

  function onDragStart(e) {
    e.set(frames[e.target.id]?.translate || [0, 0])
  }

  function onDrag(e) {
    updateFrame(e.target.id, {translate: e.beforeTranslate})
  }

  function onRotateStart(e) {
    e.set(frames[e.target.id]?.rotate || 0)
  }

  function onRotate(e) {
    updateFrame(e.target.id, {rotate: e.beforeRotate})
  }

  function onGroup(handler) {
    return ({ events }) => events.forEach(handler)
  }
}

function _snapRotate(alpha) {
  return _snap(alpha, 15);
}

function _snap(value, snapTo) {
  const toLower = value % snapTo;
  const toHigher = snapTo - Math.abs(toLower);
  return Math.abs(toLower) < toHigher ? value - toLower : value + Math.sign(toLower) * toHigher;
}

function PuzzleBoard({width, height, snapshots, addSnapshot, selectSnapshot, children}) {
  return <div className='flex'>
    <div className='relative'>
      <Container width={width} height={height} margin={5}/>
      <div className="absolute z-10 pointer-events-none" style={{ left: "calc(50% - 12rem)", top: "5rem", width: "24rem" }}>
        {children}
      </div>
    </div>
    <Snapshots add={addSnapshot} select={selectSnapshot} height={height + 10}>{snapshots}</Snapshots>
  </div>
}

function Snapshots({add, select, height, children}) {
  const time = useTimer()

  return <div className='w-56 p-4' styles={{maxHeight: `${height}px`}}>
    <div className='text-center font-bold text-3xl m-1'>{format(time)}</div>
    <button title="Click to take a snapshot" className='text-center text-2xl bg-primary w-full my-2 p-2 rounded-full text-page outline-none' onClick={() => add(time)}><Icon icon={faCameraAlt}/>&nbsp;&nbsp;Snapshot</button>

    <div className='w-full h-auto overflow-auto space-y-2 mt-4 overflow-y-scroll'>
      {children.map(snapshot => <div onClick={() => select(snapshot.time)} title="Click to restore snapshot" className='relative w-full text-center cursor-pointer hover:font-bold'>
        {format(snapshot.time)}
      </div>)}
    </div>
  </div>

  function format(time) {
    return `${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`
  }
}

function Container( {width, height, margin} ) {
  const unitWidth = 100

  return <svg width={width + 2 * margin} height={height + 2 * margin} className="pointer-events-none">
    <CheckerBoard x={margin} y={margin} width={width} height={height} size={unitWidth} />
    <rect x={margin} y={margin} width={width} height={height}
          className="snap-target stroke-2"
          style={{ fill: "none", stroke: "grey", strokeDasharray: "5,5" }} />
  </svg>
}

function CheckerBoard({x = 0, y = 0, size = 100, width, height, color = '#f5f5f5'}) {
  const columns = (width - (width % size)) / size + ((width % size) ? 1 : 0)
  const rows = (height - (height % size)) / size + ((height % size) ? 1 : 0)

  return Array(columns * rows).fill(undefined)
    .map((s, index) => {
      const col = index % columns
      const row = (index - col) / columns

      const fieldWidth = Math.min(size, Math.abs(col * size - width))
      const fieldHeight = Math.min(size, Math.abs(row * size - height))

      return ((row + col) % 2) ? null
        : <rect key={`field-${index}`}
                x={x + col * size}
                y={y + row * size}
                width={fieldWidth}
                height={fieldHeight}
                style={{ fill: color }}/>
    })
}