import React from "react";
import { Square, Triangle, Parallelogram, Puzzle } from "../../components/Puzzle";

export default function TangramPage() {
  return <div className="max-w-full w-full h-screen">
    <Puzzle width={f(Math.sqrt(8) * 2)} height={f(Math.sqrt(8) * 2)}>
      <Tangram />
      <Tangram />
      <Tangram />
      <Tangram />
    </Puzzle>
  </div>

  function Tangram() {
    return <>
      <Triangle color='#004600' size={f(2)}/>
      <Triangle color='#004600' size={f(2)}/>
      <Triangle color='#007300' size={f(Math.sqrt(2))}/>
      <Triangle color='#009600' size={f(1)}/>
      <Triangle color='#009600' size={f(1)}/>
      <Square color='#065277' size={f(1)}/>
      <Parallelogram color='#df3434' size={f(1)}/>
    </>
  }

  function f(x) {
    return x * 100
  }
}
